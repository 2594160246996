import { useEffect, useState } from 'react'
import { Button } from '../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { DialogModal } from '../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import CommonForm from '../pharmacist_form'
import { ConfirmModal } from '../../../../components/common'
import { useParams } from 'react-router-dom'
import { useOpInchargeDetails, useUpdateOpIncharge } from './api'
import { CreateSchema, formSchema } from '../create/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'

const OPInchargeDetail = () => {
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const param = useParams()
  const opInchargeId = param.id
  const { data, refetch } = useOpInchargeDetails(opInchargeId)
  const [details, setDetails] = useState<any>({})

  useEffect(() => {
    refetch()
  }, [refetch, opInchargeId])

  // // console.log(data)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'kerala' },
  })
  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }

  useEffect(() => {
    setDetails(data)
    if (data) {
      methods.reset({
        ...data,
        // dob: moment(data?.dob).toDate(),
        // joining_date: moment(data?.joining_date).toDate(),
        age: String(data?.age),
        dob: moment(data?.dob).toDate(),
        joining_date: moment(data?.joining_date).toDate(),
      })
    }
  }, [methods, data])
  const onSuccess = () => {
    setDialogModalActive(false)
    refetch()
    setSuccessModalActive(true)
  }
  const { mutate } = useUpdateOpIncharge(onSuccess)

  const onSubmit = (payload: CreateSchema) => {
    const data = {
      op_incharge: {
        ...payload,
        email: {},
        phone_number: {},
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
      },
    }
    mutate({ data, opInchargeId })
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }
  console.log(methods.watch())
  return (
    <>
      <div className="my-6 mx-6 border rounded-[1.25rem]">
        <div className="py-8 px-5">
          <div className="flex gap-4 justify-between p-4">
            <div>
              <div className="lg:text-2xl sm:text-xl font-medium leading-10	text-black Capitalism">
                {details?.name}
              </div>
              <div className="flex gap-4 justify-start pt-3">
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor flex">
                  Stream : {details?.stream}
                </div>
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor flex">
                  <div>Availability : </div>
                  {details?.availability === 'Offline' ? (
                    <div className="text-red-700">Offline</div>
                  ) : (
                    <div className="text-AvailabilityMark">Online</div>
                  )}
                </div>
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px]">
                  Joining Date :{' '}
                  {details?.joining_date &&
                    new Date(details.joining_date).toLocaleDateString('en-GB')}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <Button label={'Edit'} onClick={handleCreate} />
              {/* <Button
                label={'Remove'}
                className={'text-RemoveButton font-medium bg-RemoveButton/30'}
                primary={false}
                // onClick={handleEdit}
              /> */}
              {dialogModalActive && (
                <DialogModal
                  isOpen={true}
                  body={
                    <div className="my-5 mx-8">
                      <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
                        <u>Edit OP Incharge</u>
                      </h2>
                      {/* <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
                        <FormProvider {...methods}>
                          <CommonForm edit={true} />
                        </FormProvider>
                      </div>
                      <div className="flex gap-8 justify-center my-3">
                        <Button
                          // onClick={methods.onSubmit}
                          label={'Clear'}
                          outlined={true}
                        />
                        <Button
                          // onClick={methods.handleSubmit}
                          onClick={handleSubmit}
                          label={'Submit'}
                        />
                      </div> */}
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-4 p-4">
                          <FormProvider {...methods}>
                            <CommonForm edit={true} />
                          </FormProvider>
                        </div>
                        <div className="flex gap-4 justify-center">
                          {/* <Button
                            onClick={methods.reset}
                            label={'Clear'}
                            outlined={true}
                          /> */}

                          <Button type="submit" label={'Submit'} />
                        </div>
                      </form>
                    </div>
                  }
                  onClose={handleClose}
                  // title={'Add New Pharmacist'}
                />
              )}
            </div>
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  OP Registration Edited Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
      <div className="mb-6 mx-6 border rounded-[1.25rem]">
        <div className="py-8 px-5">
          <div className="flex gap-4 justify-between p-4">
            <div>
              <div className="lg:text-2xl sm:text-xl font-medium leading-10	text-black">
                General Info{' '}
              </div>
              <div className="flex gap-4 justify-start pt-3">
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor">
                  Gender : {details?.gender}
                </div>
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor flex">
                  Date of Birth :{' '}
                  {details?.dob &&
                    new Date(details.dob).toLocaleDateString('en-GB')}
                </div>
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor flex">
                  Age : {details?.age}
                </div>
                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px] pr-3	text-SidenavFontColor border-r-2  border-SidenavFontColor flex">
                  Phone Number : {details?.phone_number}
                </div>

                <div className="lg:text-m sm:text-sm font-normal leading-6 align-middle h-[24px]">
                  Email : {details?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-6 mx-6 border rounded-[1.25rem]">
        <div className="py-8 px-5">
          <div className="flex gap-4 justify-between p-4">
            <div>
              <div className="text-2xl font-medium leading-10	text-black">
                Address
              </div>
              <div className="flex gap-4 justify-start pt-2">
                <div className="text-m font-normal leading-6 align-middle h-[24px]">
                  {details?.address}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OPInchargeDetail
